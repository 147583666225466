import * as React from "react";
import "../style/components/textrendsCard.scss";

// components
import CardText from "./cardText";

const MAX_MOBILE_WIDTH = 992;

class TextrendsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { content, extraClass } = this.props;

    return (
      <>
        <div className={"textrendsCard " + extraClass}>
          {content.imageFirst ? (
            <>
              <div className="row">
                <div className={content.firstCol + " borderRadiusLeft"}>
                  <img src={content.image.src} alt={content.image.alt} />
                </div>
                <div className={content.secondCol}>
                  <div className="paddingTextRight">
                    <CardText content={content}></CardText>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row flex-column-reverse flex-sm-column-reverse flex-md-row">
                <div className={content.firstCol}>
                  <div className="paddingTextLeft">
                    <CardText content={content}></CardText>
                  </div>
                </div>
                <div className={content.secondCol + " borderRadiusRight"}>
                  <img src={content.image.src} alt={content.image.alt} />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default TextrendsCard;
