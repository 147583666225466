import * as React from "react";

// components
//import Button from "./_partials/_button"; -- TO BE ADDED WHEN NEXT SEASON OPENS --

const MAX_MOBILE_WIDTH = 992;

class CardText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { content } = this.props;

    return (
      <>
        {content.title && (
          <h1 className={content.title.className}>{content.title.text}</h1>
        )}
        {content.subtitle && (
          <h2 className={content.subtitle.className}>
            {content.subtitle.text}
          </h2>
        )}
        {content.description && (
          <p className={content.description.className}>
            {content.description.text}
          </p>
        )}
        {/* 
        -- TO BE ADDED WHEN NEXT SEASON OPENS --
      content.cta && <Button cta={content.cta}></Button>
      */}
      </>
    );
  }
}

export default CardText;
