import * as React from "react";
import "../style/components/carrousel.scss";

// components
import { Slide } from "react-slideshow-image";
import TextrendsCard from "./textrendsCard";
import TopCircleCard from "./topCircleCard";
import Card from "./_partials/_card";
import BestProductCard from "./textrends/bestProductCard";

const MAX_MOBILE_WIDTH = 992;

class Carrousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { content, arrows } = this.props;
    const slidesConfig = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      autoplay: false,
      indicators: true,
      canSwipe: true,
      arrows: window.innerWidth > MAX_MOBILE_WIDTH ? true : false,
      prevArrow: (
        <div style={{ marginRight: "-30px" }} className={arrows.extraClass}>
          <img src={arrows.left} alt="Arrow" />
        </div>
      ),
      nextArrow: (
        <div
          style={{ marginLeft: "-30px" }}
          className={"arrow " + arrows.extraClass}
        >
          <img src={arrows.right} alt="Arrow" />
        </div>
      ),
    };

    const component = (card, i) => {
      switch (content.cardType) {
        case "topCircleCard":
          return <TopCircleCard key={i} content={card} />;
        case "textrendsCard":
          return (
            <TextrendsCard
              key={i}
              content={card}
              extraClass={content.cardExtraClass}
            />
          );
        case "bestProductCard":
          return <BestProductCard key={i} product={card} winners={content} />;
        default:
          return (
            <Card
              index={i}
              info={card}
              backgroundColor={card.backgroundColor}
              center={card.center}
              extraClass={card.extraClass}
            />
          );
      }
    };

    return (
      <>
        <Slide {...slidesConfig}>
          {content.cards.map((card, index) => (
            <div
              key={index}
              className={"each-slide " + content.carrouselExtraClass}
            >
              {component(card, index)}
            </div>
          ))}
        </Slide>
      </>
    );
  }
}

export default Carrousel;
