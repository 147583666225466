/**
 * Foursource page
 * https://foursource.com/ispo-textrends-award
 */

import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

import { pageTexts } from '../content/ispo-textrends-award';

// components
import Layout from '../components/layout';
import TextImgBackgroundFull from '../components/textImgBackgroundFull';
import BigTextrendsCard from '../components/bigTextrendsCard';
import TopCircleCard from '../components/topCircleCard';
import Card from '../components/_partials/_card';
import Text from '../components/text';
import Button from '../components/_partials/_button';
import Carrousel from '../components/carrousel';
import TwoColsCard from '../components/twoColsCard';
import TextrendsModal from '../components/textrends/textrendsModal';

const MOBILE_WIDTH = 992;
class ispoTextrendsAwardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: typeof window !== 'undefined' ? (window.innerWidth <= MOBILE_WIDTH ? true : false) : '',
    };
    this.t = props.t;
    this.content = pageTexts(this.t);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };

  render() {
    return (
      <>
        <Layout pageName='ispo-textrends-award' whiteMenu={false}>
          {/* Banner */}
          <TextImgBackgroundFull info={this.state.mobile ? this.content.topInfoMobile : this.content.topInfo} />
          <div className='paddingSidesBig pt-60 pt-sm-60 pb-100 pb-sm-40'>
            {/* About */}
            <Text text={this.content.about} />
            {/* Next Season */}
            <BigTextrendsCard info={this.content.bigCard} cta={this.content.bigCard.cta} />
          </div>
          {/* Benefits */}
          <div className='paddingSidesBig pt-150 pt-sm-40 pb-100 pb-sm-40 topBottomDiagonal backgroundGreyMd'>
            <Text text={this.content.benefits.title}></Text>
            {this.state.mobile ? (
              <Carrousel content={this.content.benefits} arrows={this.content.benefits.carrouselArrows} />
            ) : (
              <div className='flex-column align-items-center'>
                <div className='row justify-content-center'>
                  {this.content.benefits.cards.map((content, i) => (
                    <TopCircleCard key={i} content={content} />
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* Application process */}
          <div className='paddingSidesBig pt-80 pt-sm-40 pb-80 pb-sm-40'>
            <Text text={this.content.applicationProcess.title} />
            {this.state.mobile ? (
              <Carrousel
                content={this.content.applicationProcess}
                arrows={this.content.applicationProcess.carrouselArrows}
              />
            ) : (
              <div className='flex-column align-items-center'>
                <div className='row justify-content-center'>
                  {this.content.applicationProcess.cards.map((content, i) => (
                    <Card
                      key={i}
                      info={content}
                      backgroundColor={content.backgroundColor}
                      center={content.center}
                      extraClass={content.extraClass}
                    />
                  ))}
                </div>
              </div>
            )}
            {/* Apply Button */}
            <div className='d-flex justify-content-center pt-80'>
              <Button cta={this.content.ctas.applyNow}></Button>
            </div>
          </div>
          {/* Judging & Criteria */}
          <div className='paddingSidesBig pt-150 pt-sm-40 pb-100 pb-sm-40 topBottomDiagonal backgroundGreyMd'>
            <Text text={this.content.judgingAndCriteria.title} />
            <Text text={this.content.judgingAndCriteria.criteria.title} />
            <Carrousel
              content={this.content.judgingAndCriteria.criteria}
              arrows={this.content.judgingAndCriteria.criteria.carrouselArrows}
            />
            <div className='pt-80'>
              <Text text={this.content.judgingAndCriteria.jury.title} />
              <Carrousel
                content={this.content.judgingAndCriteria.jury}
                arrows={this.content.judgingAndCriteria.jury.carrouselArrows}
              />
            </div>
          </div>
          {/* Season Winners */}
          <div className='paddingSidesBig pt-80 pt-sm-40 pb-80 pb-sm-40'>
            <Text text={this.content.springSummer2024Winners.title} />
            <div className='mt-100 mt-sm-45 mb-100 mb-sm-60 d-flex justify-content-center'>
              <TextrendsModal
                video={this.content.seasonWinners.modalContent.video}
                cta={this.content.ctas.winnersCeremony}
              />
            </div>
            <div className='seasonWinners mx-auto'>
              <div className='bestProductCard'>
                <Carrousel
                  content={this.content.springSummer2024Winners}
                  arrows={this.content.springSummer2024Winners.carrouselArrows}
                />
              </div>
            </div>
            <div className='pt-80 pt-sm-96 d-flex justify-content-center'>
              <Button cta={this.content.ctas.textrendsHub} />
            </div>
          </div>
          {/* ISPO Textrends Award */}
          <div className='paddingSidesBig pt-150 pt-sm-40 pb-100 pb-sm-40 topBottomDiagonal backgroundGreyMd'>
            <Text text={this.content.inThePress.title} />
            <div className='d-flex justify-content-center'>
              <div>
                {this.content.inThePress.articles.map((article, i) => (
                  <a
                    key={i}
                    href={article.url}
                    target='_blank'
                    rel='noreferrer'
                    className='greenHover d-flex align-items-center align-items-sm-baseline align-items-sm-center mt-25 mt-sm-0'
                  >
                    <img src={this.content.arrows.green.src} alt={this.content.arrows.green.alt} />
                    <p className='font20 font-weight-bold fontWeightSm400 ml-25 greenHover'>{article.text}</p>
                  </a>
                ))}
              </div>
            </div>
          </div>
          {/* Trendbook */}
          <div className='paddingSidesBig pt-80 pt-sm-40 pb-80 pb-sm-40'>
            <Text text={this.content.trendbook.title}></Text>
            <TwoColsCard info={this.content.trendbook}></TwoColsCard>
          </div>
          {/* Contact */}
          <div className='paddingSidesBig pt-175 pt-sm-60 pb-100 pb-sm-80 topDiagonal backgroundGreyMd'>
            <a href={this.content.contact.email.mailto}>
              <p className='text-center colorMain hoverEffect font24'>{this.content.contact.email.text}</p>
            </a>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(ispoTextrendsAwardPage);
