import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

import "../../style/components/textrendsModal.scss";

export default function TextrendsModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { video, cta } = props;

  return (
    <>
      <Button
        onClick={handleShow}
        className={
          cta.type + " ctaButton " + (cta.extraClass ? cta.extraClass : "")
        }
      >
        {cta.svg && (
          <img src={cta.svg.src} alt={cta.svg.alt} className="mr-5" />
        )}
        {cta.text}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <div class="textrends-video">
          <iframe
            title="vimeo-player"
            src={video}
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
}
