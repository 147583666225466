// images
import TopBackground from '../images/ispo-textrends-award/ispo-textrends-hub-header-image.png';
import TopBackgroundMobile from '../images/ispo-textrends-award/ispo-textrends-hub-header-image.png';
import BigCardBackgroundImg from '../images/ispo-textrends-award/fall-winter-24-25-header.jpg';
import IspoTextrendsLogo from '../images/ispo-textrends-award/ispo-textrends-logo.svg';

import GreenArrow from '../images/green-arrow-right.svg';
import PlayButton from '../images/play-button.svg';
import Trendbook from '../images/ispo-textrends-award/ss24-digital-trendbook.png';

import BlackLeftArrow from '../images/black-arrow-left.svg';
import BlackRightArrow from '../images/black-arrow-right.svg';
import WhiteLeftArrow from '../images/white-arrow-left.svg';
import WhiteRightArrow from '../images/white-arrow-right.svg';

import svg01 from '../images/ispo-textrends-award/benefits/01.svg';
import svg02 from '../images/ispo-textrends-award/benefits/02.svg';
import svg03 from '../images/ispo-textrends-award/benefits/03.svg';
import svg04 from '../images/ispo-textrends-award/benefits/04.svg';
import svg05 from '../images/ispo-textrends-award/benefits/05.svg';
import svg06 from '../images/ispo-textrends-award/benefits/06.svg';
import svg07 from '../images/ispo-textrends-award/benefits/07.svg';
import svg08 from '../images/ispo-textrends-award/benefits/08.svg';
import svg09 from '../images/ispo-textrends-award/benefits/09.svg';

import Criteria01 from '../images/ispo-textrends-award/criteria/criteria-01.png';
import Criteria02 from '../images/ispo-textrends-award/criteria/criteria-02.png';
import Criteria03 from '../images/ispo-textrends-award/criteria/criteria-03.png';
import Criteria04 from '../images/ispo-textrends-award/criteria/criteria-04.png';
import Criteria05 from '../images/ispo-textrends-award/criteria/criteria-05.png';
import Criteria06 from '../images/ispo-textrends-award/criteria/criteria-06.png';
import Criteria07 from '../images/ispo-textrends-award/criteria/criteria-07.png';
import Criteria08 from '../images/ispo-textrends-award/criteria/criteria-08.png';

import Jury01 from '../images/ispo-textrends-award/jury/meet-the-jury-01.png';
import Jury02 from '../images/ispo-textrends-award/jury/braz-costa.png';
import Jury03 from '../images/ispo-textrends-award/jury/giusy-bettoni.png';
import Jury04 from '../images/ispo-textrends-award/jury/katharina-mayer.png';
import Jury05 from '../images/ispo-textrends-award/jury/louisa-smith.png';
import Jury06 from '../images/ispo-textrends-award/jury/mia-tapio.png';
import Jury07 from '../images/ispo-textrends-award/jury/salome-wilson.png';

import BestProductLabel from '../images/ispo-textrends-award/best-products/best-product-label.svg';
import BestProductAcceleratedEco from '../images/ispo-textrends-award/best-products/ae-fenc-toopgreen-bio3-far-eastern-new-centry-bp-slide.jpg';
import BestProductPerformingFinishes from '../images/ispo-textrends-award/best-products/pf-blanc-sfz110-11-wujiang-sunfeng-textile-co-ltd-bp-slide.jpg';
import BestProductBaseLayer from '../images/ispo-textrends-award/best-products/bl-bio-based-polyamide-paradise-textiles-alpine-group--bp-slide.jpg';
import BestProductSecondLayer from '../images/ispo-textrends-award/best-products/sl-rwd400-310-chia-her-industrial-co-ltd-bp-slide.jpg';
import BestProductOuterLayer from '../images/ispo-textrends-award/best-products/ol-warp-knitted-fleece-toread-holdings-group-co-ltd-bp-slide.jpg';
import BestProductMembranesAndCoating from '../images/ispo-textrends-award/best-products/mc-dtl284-raaylo-e-dye-taiwan-limited-bp-slide.jpg';
import BestProductStreetSports from '../images/ispo-textrends-award/best-products/ss-sw-80376sc0-4-stormega-singtex-bp-slide.jpg';
import BestProductSoftEquipment from '../images/ispo-textrends-award/best-products/se-cfdks008-invista-textiles-uk-limited-bp-slide.jpg';
import BestProductFibersAndInsulation from '../images/ispo-textrends-award/best-products/in-ecoloft-active-sr-youngone-co-ltd-bp-slide.jpg';
import BestProductAccessories from '../images/ispo-textrends-award/best-products/a-printed-zipper-with-dream-coating-series-tai-hing-zipper-factory-company-limited-bp-slide.jpg';
import BestProductTrims from '../images/ispo-textrends-award/best-products/t-recycled-paper-transfers-uab-heat-transfers-company-bp-slide.jpg';

import SpringSummer2024BestProductLabel from '../images/ispo-textrends-award/spring-summer-2024/best-product-label.svg';
import SpringSummer2024BestProductAcceleratedEco from '../images/ispo-textrends-award/spring-summer-2024/ae-3t-bio-born.jpg';
import SpringSummer2024BestProductBaseLayer from '../images/ispo-textrends-award/spring-summer-2024/bl-6061.jpg';
import SpringSummer2024BestProductMembranesAndCoating from '../images/ispo-textrends-award/spring-summer-2024/mc-fy22085-2.jpg';
import SpringSummer2024BestProductOuterLayer from '../images/ispo-textrends-award/spring-summer-2024/ol-sorona-agile-woven-bi-stretch-ys7705r.jpg';
import SpringSummer2024BestProductPerformingFinishes from '../images/ispo-textrends-award/spring-summer-2024/pf-dhdd20220026-phyllis.jpg';
import SpringSummer2024BestProductSecondLayer from '../images/ispo-textrends-award/spring-summer-2024/sl-xdy21a109-ne.jpg';
import SpringSummer2024BestProductSoftEquipment from '../images/ispo-textrends-award/spring-summer-2024/se-c0168-c-9l.jpg';
import SpringSummer2024BestProductStreetSports from '../images/ispo-textrends-award/spring-summer-2024/ss-xdy21a125-ll.jpg';
import SpringSummer2024BestProductTrimsAndAccessories from '../images/ispo-textrends-award/spring-summer-2024/a-3t-flag.jpg';

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t('ispo-textrends-award.topTitle'),
        colorClass: 'colorLight',
        extraClass: 'font50 ',
      },
      featuredLogo: {
        src: IspoTextrendsLogo,
        alt: t('ispo-textrends-award.featuredLogo.alt'),
        url: t('ispo-textrends-award.featuredLogo.url'),
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t('ispo-textrends-award.imgAlt'),
      },
    },
    topInfoMobile: {
      title: {
        text: t('ispo-textrends-award.topTitle'),
        colorClass: 'colorLight',
        extraClass: 'font32',
      },
      featuredLogo: {
        src: IspoTextrendsLogo,
        alt: t('ispo-textrends-award.featuredLogo.alt'),
        url: t('ispo-textrends-award.featuredLogo.url'),
      },
      bottomDiagonal: false,
      opacity: true,
      textMiddle: true,
      image: {
        src: TopBackgroundMobile,
        alt: t('ispo-textrends-award.imgAlt'),
        height425: true,
      },
    },
    ctas: {
      applyNow: {
        text: t('ispo-textrends-award.ctas.applyNow.text'),
        url: t('ispo-textrends-award.ctas.applyNow.url'),
        type: 'mainColor',
        target: '_blank',
      },
      textrendsHub: {
        text: t('ispo-textrends-award.ctas.textrendsHub.text'),
        url:
          (process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL) +
          t('ispo-textrends-award.ctas.textrendsHub.url'),
        type: 'mainColor',
        extraClass: 'bigWidth',
      },
      winnersCeremony: {
        text: t('ispo-textrends-award.ctas.winnersCeremony.text'),
        type: 'whiteColor',
        extraClass: 'greenBorder d-flex align-items-center',
        svg: {
          src: PlayButton,
          alt: 'Play button',
        },
      },
    },
    arrows: {
      green: {
        src: GreenArrow,
        alt: 'Green arrow',
      },
    },
    about: [
      {
        text: t('ispo-textrends-award.about.title'),
        extraClass: 'pb-16 font40 text-center',
        textType: 'title',
      },
      {
        text: t('ispo-textrends-award.about.text'),
        textType: 'paragraph',
        extraClass:
          'font17 lineHeight1_7 letterSpacing0_1 pb-50 pb-sm-70 text-center pr-200 pl-200 pr-md-75 pl-md-75 pr-sm-0 pl-sm-0',
      },
    ],
    bigCard: {
      header: {
        title: {
          text: t('ispo-textrends-award.bigCard.preTitle'),
          colorClass: 'colorLight',
          extraClass: 'font24 lineHeight1_3 fontWeight400 text-left',
        },
        subtitle: {
          text: t('ispo-textrends-award.bigCard.title'),
          colorClass: 'colorLight',
          extraClass: 'font50 text-left',
        },
        image: {
          src: BigCardBackgroundImg,
          alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
          topBorderRadius: true,
        },
        bottomDiagonal: false,
        opacity: true,
      },
      text: [
        {
          text: t('ispo-textrends-award.bigCard.text'),
          colorClass: 'colorDarkLight',
          textType: 'paragraph',
          extraClass: 'font16 lineHeight1_5',
        },
      ],
      cta: {
        text: t('ispo-textrends-award.ctas.applyNow.text'),
        url: t('ispo-textrends-award.ctas.applyNow.url'),
        extraClass: 'mainColor',
        target: '_blank',
      },
      bottomDiagonal: true,
      opacity: false,
    },
    benefits: {
      title: [
        {
          text: t('ispo-textrends-award.benefits.title'),
          textType: 'title',
          extraClass: 'font40 text-center',
        },
      ],
      cardType: 'topCircleCard',
      carrouselExtraClass: 'd-flex justify-content-center',
      carrouselArrows: {
        left: BlackLeftArrow,
        right: BlackRightArrow,
        extraClass: 'circleArrow',
      },
      cards: [
        {
          svg: {
            src: svg01,
            alt: 'tags',
          },
          text: t('ispo-textrends-award.benefits.first'),
        },
        {
          svg: {
            src: svg02,
            alt: 'like',
          },
          text: t('ispo-textrends-award.benefits.second'),
        },
        {
          svg: {
            src: svg03,
            alt: 'star',
          },
          text: t('ispo-textrends-award.benefits.third'),
        },
        {
          svg: {
            src: svg04,
            alt: 'membership',
          },
          text: t('ispo-textrends-award.benefits.forth'),
        },
        {
          svg: {
            src: svg05,
            alt: 'camera',
          },
          text: t('ispo-textrends-award.benefits.fifth'),
        },
        {
          svg: {
            src: svg06,
            alt: 'trendbook',
          },
          text: t('ispo-textrends-award.benefits.sixth'),
        },
        {
          svg: {
            src: svg07,
            alt: 'foursource',
          },
          text: t('ispo-textrends-award.benefits.seventh'),
        },
        {
          svg: {
            src: svg08,
            alt: 'filming camera',
          },
          text: t('ispo-textrends-award.benefits.eighth'),
        },
        {
          svg: {
            src: svg09,
            alt: 'manual',
          },
          text: t('ispo-textrends-award.benefits.ninth'),
        },
      ],
    },
    applicationProcess: {
      title: [
        {
          text: t('ispo-textrends-award.applicationProcess.title'),
          textType: 'title',
          extraClass: 'pb-30 pb-sm-0 font40 text-center',
        },
      ],
      carrouselExtraClass: 'd-flex justify-content-center',
      carrouselArrows: {
        left: BlackLeftArrow,
        right: BlackRightArrow,
        extraClass: 'circleArrow',
      },
      cards: [
        {
          title: t('ispo-textrends-award.applicationProcess.first.title'),
          text: t('ispo-textrends-award.applicationProcess.first.text'),
          backgroundColor: 'backgroundGreen',
          center: true,
          extraClass: 'smallCard',
        },
        {
          title: t('ispo-textrends-award.applicationProcess.second.title'),
          text: t('ispo-textrends-award.applicationProcess.second.text'),
          backgroundColor: 'backgroundGreen',
          center: true,
          extraClass: 'smallCard',
        },
        {
          title: t('ispo-textrends-award.applicationProcess.third.title'),
          text: t('ispo-textrends-award.applicationProcess.third.text'),
          backgroundColor: 'backgroundGreen',
          center: true,
          extraClass: 'smallCard',
        },
      ],
    },
    judgingAndCriteria: {
      title: [
        {
          text: t('ispo-textrends-award.judgingAndCriteria.title'),
          textType: 'title',
          extraClass: 'pb-50 pb-sm-16 font40 text-center',
        },
      ],
      criteria: {
        title: [
          {
            text: t('ispo-textrends-award.judgingAndCriteria.criteria.title'),
            textType: 'subtitle',
            extraClass: 'pb-40 pb-sm-16 font32 text-center',
          },
        ],
        cardType: 'textrendsCard',
        cardExtraClass: 'h-sm-650',
        carrouselExtraClass: 'd-flex justify-content-center',
        carrouselArrows: {
          left: BlackLeftArrow,
          right: BlackRightArrow,
          extraClass: 'circleArrow',
        },
        cards: [
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.first.description'),
              className: 'font24 lineHeight1_3 mainColor font-weight-bold fontWeightSm400',
            },
            cta: {
              text: t('ispo-textrends-award.ctas.applyNow.text'),
              url: t('ispo-textrends-award.ctas.applyNow.url'),
              extraClass: 'mainColor',
              target: '_blank',
            },
            image: {
              src: Criteria01,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.second.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.second.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria02,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.third.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.third.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria03,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.forth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.forth.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria04,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.fifth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.fifth.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria05,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.sixth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.sixth.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria06,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.seventh.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.seventh.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria07,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: false,
            firstCol: 'col-12 col-sm-12 col-md-6 text-center text-md-left',
            secondCol: 'col-12 col-sm-12 col-md-6',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.eighth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.criteria.cards.eighth.description'),
              className: 'font24 lineHeight1_3 pt-30',
            },
            image: {
              src: Criteria08,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
        ],
      },
      jury: {
        title: [
          {
            text: t('ispo-textrends-award.judgingAndCriteria.jury.title'),
            textType: 'subtitle',
            extraClass: 'pb-40 pb-sm-16 font32 text-center',
          },
        ],
        cardType: 'textrendsCard',
        cardExtraClass: 'h-sm-730',
        carrouselExtraClass: 'd-flex justify-content-center',
        carrouselArrows: {
          left: BlackLeftArrow,
          right: BlackRightArrow,
          extraClass: 'circleArrow',
        },
        cards: [
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-6',
            secondCol: 'col-12 col-sm-12 col-md-6 text-center text-sm-left',
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.first.description'),
              className: 'font24 lineHeight1_3 font-weight-bold fontWeightSm400',
            },
            cta: {
              text: t('ispo-textrends-award.ctas.applyNow.text'),
              url: t('ispo-textrends-award.ctas.applyNow.url'),
              extraClass: 'mainColor',
              target: '_blank',
            },
            image: {
              src: Jury01,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.second.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.second.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.second.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury02,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.third.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.third.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.third.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury03,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.forth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.forth.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.forth.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury04,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.fifth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.fifth.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.fifth.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury05,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.sixth.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.sixth.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.sixth.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury06,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
          {
            imageFirst: true,
            firstCol: 'col-12 col-sm-12 col-md-3',
            secondCol: 'col-12 col-sm-12 col-md-9 text-center text-md-left',
            title: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.seventh.title'),
              className: 'font32 colorMain font-weight-bold',
            },
            subtitle: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.seventh.subtitle'),
              className: 'font24 lineHeight1_3 colorMain',
            },
            description: {
              text: t('ispo-textrends-award.judgingAndCriteria.jury.cards.seventh.description'),
              className: 'font20 lineHeight1_3 pt-25 line-clamp',
            },
            image: {
              src: Jury07,
              alt: t('ispo-textrends-award.bigCard.backgroundImgAlt'),
            },
          },
        ],
      },
    },
    seasonWinners: {
      modalContent: {
        video: 'https://www.youtube.com/embed/EJJIdKeJpKk?loop=1&modestbranding=1&rel=0',
      },
      title: [
        {
          text: t('ispo-textrends-award.seasonWinners.title'),
          textType: 'title',
          extraClass: 'font40 text-center',
        },
      ],
      season: t('ispo-textrends-award.seasonWinners.season'),
      label: {
        src: BestProductLabel,
        alt: 'Best Product',
      },
      cardType: 'bestProductCard',
      carrouselExtraClass: 'bestProductCard',
      carrouselArrows: {
        left: WhiteLeftArrow,
        right: WhiteRightArrow,
        extraClass: '',
      },
      cards: [
        {
          title: t('ispo-textrends-award.seasonWinners.acceleratedEco.title'),
          company: t('ispo-textrends-award.seasonWinners.acceleratedEco.company'),
          name: t('ispo-textrends-award.seasonWinners.acceleratedEco.product'),
          image: {
            src: BestProductAcceleratedEco,
            alt: t('ispo-textrends-award.seasonWinners.acceleratedEco.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.acceleratedEco.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.performingFinishes.title'),
          company: t('ispo-textrends-award.seasonWinners.performingFinishes.company'),
          name: t('ispo-textrends-award.seasonWinners.performingFinishes.product'),
          image: {
            src: BestProductPerformingFinishes,
            alt: t('ispo-textrends-award.seasonWinners.performingFinishes.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.performingFinishes.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.baseLayer.title'),
          company: t('ispo-textrends-award.seasonWinners.baseLayer.company'),
          name: t('ispo-textrends-award.seasonWinners.baseLayer.product'),
          image: {
            src: BestProductBaseLayer,
            alt: t('ispo-textrends-award.seasonWinners.baseLayer.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.baseLayer.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.secondLayer.title'),
          company: t('ispo-textrends-award.seasonWinners.secondLayer.company'),
          name: t('ispo-textrends-award.seasonWinners.secondLayer.product'),
          image: {
            src: BestProductSecondLayer,
            alt: t('ispo-textrends-award.seasonWinners.secondLayer.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.secondLayer.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.outerLayer.title'),
          company: t('ispo-textrends-award.seasonWinners.outerLayer.company'),
          name: t('ispo-textrends-award.seasonWinners.outerLayer.product'),
          image: {
            src: BestProductOuterLayer,
            alt: t('ispo-textrends-award.seasonWinners.outerLayer.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.outerLayer.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.membranesAndCoating.title'),
          company: t('ispo-textrends-award.seasonWinners.membranesAndCoating.company'),
          name: t('ispo-textrends-award.seasonWinners.membranesAndCoating.product'),
          image: {
            src: BestProductMembranesAndCoating,
            alt: t('ispo-textrends-award.seasonWinners.membranesAndCoating.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.membranesAndCoating.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.streetSports.title'),
          company: t('ispo-textrends-award.seasonWinners.streetSports.company'),
          name: t('ispo-textrends-award.seasonWinners.streetSports.product'),
          image: {
            src: BestProductStreetSports,
            alt: t('ispo-textrends-award.seasonWinners.streetSports.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.streetSports.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.softEquipment.title'),
          company: t('ispo-textrends-award.seasonWinners.softEquipment.company'),
          name: t('ispo-textrends-award.seasonWinners.softEquipment.product'),
          image: {
            src: BestProductSoftEquipment,
            alt: t('ispo-textrends-award.seasonWinners.softEquipment.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.softEquipment.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.fibersAndInsulation.title'),
          company: t('ispo-textrends-award.seasonWinners.fibersAndInsulation.company'),
          name: t('ispo-textrends-award.seasonWinners.fibersAndInsulation.product'),
          image: {
            src: BestProductFibersAndInsulation,
            alt: t('ispo-textrends-award.seasonWinners.fibersAndInsulation.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.fibersAndInsulation.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.accessories.title'),
          company: t('ispo-textrends-award.seasonWinners.accessories.company'),
          name: t('ispo-textrends-award.seasonWinners.accessories.product'),
          image: {
            src: BestProductAccessories,
            alt: t('ispo-textrends-award.seasonWinners.accessories.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.accessories.url'),
        },
        {
          title: t('ispo-textrends-award.seasonWinners.trims.title'),
          company: t('ispo-textrends-award.seasonWinners.trims.company'),
          name: t('ispo-textrends-award.seasonWinners.trims.product'),
          image: {
            src: BestProductTrims,
            alt: t('ispo-textrends-award.seasonWinners.trims.product'),
          },
          url: t('ispo-textrends-award.seasonWinners.trims.url'),
        },
      ],
    },
    springSummer2024Winners: {
      title: [
        {
          text: t('ispo-textrends-award.springSummer2024Winners.title'),
          textType: 'title',
          extraClass: 'font40 text-center mb-50 pb-0',
        },
      ],
      season: t('ispo-textrends-award.springSummer2024Winners.season'),
      label: {
        src: SpringSummer2024BestProductLabel,
        alt: 'Best Product',
      },
      cardType: 'bestProductCard',
      carrouselExtraClass: 'bestProductCard',
      carrouselArrows: {
        left: WhiteLeftArrow,
        right: WhiteRightArrow,
        extraClass: '',
      },
      cards: [
        {
          title: t('ispo-textrends-award.springSummer2024Winners.acceleratedEco.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.acceleratedEco.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.acceleratedEco.product'),
          image: {
            src: SpringSummer2024BestProductAcceleratedEco,
            alt: t('ispo-textrends-award.springSummer2024Winners.acceleratedEco.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.acceleratedEco.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.baseLayer.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.baseLayer.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.baseLayer.product'),
          image: {
            src: SpringSummer2024BestProductBaseLayer,
            alt: t('ispo-textrends-award.springSummer2024Winners.baseLayer.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.baseLayer.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.membranesAndCoating.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.membranesAndCoating.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.membranesAndCoating.product'),
          image: {
            src: SpringSummer2024BestProductMembranesAndCoating,
            alt: t('ispo-textrends-award.springSummer2024Winners.membranesAndCoating.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.membranesAndCoating.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.outerLayer.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.outerLayer.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.outerLayer.product'),
          image: {
            src: SpringSummer2024BestProductOuterLayer,
            alt: t('ispo-textrends-award.springSummer2024Winners.outerLayer.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.outerLayer.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.performingFinishes.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.performingFinishes.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.performingFinishes.product'),
          image: {
            src: SpringSummer2024BestProductPerformingFinishes,
            alt: t('ispo-textrends-award.springSummer2024Winners.performingFinishes.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.performingFinishes.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.secondLayer.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.secondLayer.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.secondLayer.product'),
          image: {
            src: SpringSummer2024BestProductSecondLayer,
            alt: t('ispo-textrends-award.springSummer2024Winners.secondLayer.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.secondLayer.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.softEquipment.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.softEquipment.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.softEquipment.product'),
          image: {
            src: SpringSummer2024BestProductSoftEquipment,
            alt: t('ispo-textrends-award.springSummer2024Winners.softEquipment.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.softEquipment.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.streetSports.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.streetSports.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.streetSports.product'),
          image: {
            src: SpringSummer2024BestProductStreetSports,
            alt: t('ispo-textrends-award.springSummer2024Winners.streetSports.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.streetSports.url'),
        },
        {
          title: t('ispo-textrends-award.springSummer2024Winners.trimsAndAccessories.title'),
          company: t('ispo-textrends-award.springSummer2024Winners.trimsAndAccessories.company'),
          name: t('ispo-textrends-award.springSummer2024Winners.trimsAndAccessories.product'),
          image: {
            src: SpringSummer2024BestProductTrimsAndAccessories,
            alt: t('ispo-textrends-award.springSummer2024Winners.trimsAndAccessories.product'),
          },
          url: t('ispo-textrends-award.springSummer2024Winners.trimsAndAccessories.url'),
        },
      ],
    },
    inThePress: {
      title: [
        {
          text: t('ispo-textrends-award.inThePress.title'),
          textType: 'title',
          extraClass: 'font32 pb-sm-16 font40 text-center',
        },
        {
          text: t('ispo-textrends-award.inThePress.subtitle'),
          textType: 'subtitle',
          extraClass: 'font32 text-center pb-16 pb-sm-24',
        },
      ],
      articles: [
        {
          text: t('ispo-textrends-award.inThePress.first.text'),
          url: t('ispo-textrends-award.inThePress.first.url'),
        },
        {
          text: t('ispo-textrends-award.inThePress.second.text'),
          url: t('ispo-textrends-award.inThePress.second.url'),
        },
        {
          text: t('ispo-textrends-award.inThePress.third.text'),
          url: t('ispo-textrends-award.inThePress.third.url'),
        },
        {
          text: t('ispo-textrends-award.inThePress.forth.text'),
          url: t('ispo-textrends-award.inThePress.forth.url'),
        },
        {
          text: t('ispo-textrends-award.inThePress.fifth.text'),
          url: t('ispo-textrends-award.inThePress.fifth.url'),
        },
      ],
    },
    trendbook: {
      title: [
        {
          text: t('ispo-textrends-award.trendbook.title'),
          textType: 'title',
          extraClass: 'font40 text-center textrends-title pb-80 pb-sm-32',
        },
      ],
      image: {
        src: Trendbook,
        alt: t('ispo-textrends-award.trendbook.imageAlt'),
      },
      text: [
        {
          text: t('ispo-textrends-award.trendbook.intro'),
          textType: 'paragraph',
          extraClass: 'font17 lineHeight1_5 pb-40 pb-sm-24 font-weight-bold fontWeightSm400 text-center text-md-left',
        },
        {
          text: t('ispo-textrends-award.trendbook.digitalPrice'),
          textType: 'paragraph',
          extraClass: 'font16 pb-20 text-center text-md-left',
        },
        {
          text: t('ispo-textrends-award.trendbook.printedPrice'),
          textType: 'paragraph',
          extraClass: 'font16 text-center text-md-left',
        },
      ],
      cta: {
        text: t('ispo-textrends-award.ctas.trendbook.text'),
        url: t('ispo-textrends-award.ctas.trendbook.url'),
        target: '_blank',
        type: 'mainColor',
        extraClass: 'bigWidth d-block mx-auto ml-md-0 mt-50 mt-sm-40',
      },
    },
    contact: {
      email: {
        text: t('ispo-textrends-award.contact.email.text'),
        mailto: t('ispo-textrends-award.contact.email.mailto'),
      },
    },
  };
};
