import React from "react";

import "../style/components/topCircleCard.scss";

class TopCircleCard extends React.Component {
  render() {
    const { content } = this.props;
    return (
      <>
        <div className={"top-circle-card p-20 mt-90 mx-auto mb-0"}>
          {content.svg && (
            <div className={"svg d-flex justify-content-center"}>
              <img src={content.svg.src} alt={content.svg.alt} className={"svg"}></img>
            </div>
          )}
          <div className={"text-center"}>
            <p>{content.text}</p>
          </div>
        </div>
      </>
    );
  }
}

export default TopCircleCard;
