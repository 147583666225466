import * as React from "react";
import "../../style/components/bestProductCard.scss";

const MAX_MOBILE_WIDTH = 992;

class BestProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { product, winners } = this.props;

    return (
      <>
        <div
          className="bestProductCard"
          style={{ backgroundImage: `url(${product.image.src})` }}
        >
          <div className="overlay">
            <div className="row flex-column-reverse flex-sm-column-reverse flex-md-row">
              <div className="col-12 col-sm-12 col-md-8">
                <div className="product text-white font24 lineHeight1_3">
                  <a href={product.url} target="_blank" rel="noreferrer">
                    <p>
                      <span className="font-weight-bold">{product.title}</span>{" "}
                      - {winners.season}: <br />
                      <span className="font-weight-bold pt-sm-24">
                        {product.company}
                      </span>
                      <br />
                      <span className="font20">{product.name}</span>
                    </p>
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 m-auto">
                <img
                  src={winners.label.src}
                  alt={winners.label.alt}
                  className="d-none d-sm-block  ml-auto mr-25"
                />
              </div>
            </div>
          </div>
          <img
            src={winners.label.src}
            alt={winners.label.alt}
            className="d-sm-none d-block ml-auto pr-15 pt-15"
          />
        </div>
      </>
    );
  }
}

export default BestProductCard;
