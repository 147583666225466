import * as React from 'react';
import classNames from 'classnames';

import '../style/components/bigTextrendsCard.scss';

// components
import Button from './_partials/_button';
import TextImgBackgroundFull from '../components/textImgBackgroundFull';
import Text from '../components/text';

const MAX_MOBILE_WIDTH = 992;

class BigTextrendsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { isTextFirst, info, cta } = this.props;

    return (
      <>
        <div
          className={classNames(
            'bigTextrendsCard pb-50',
            { backgroundGrey: info.backgroundGrey },
            { 'topBottomDiagonal pt-100': info.topDiagonal },
            { pushTopDiagonal: info.pushTop },
            { noRight: isTextFirst },
            { noLeft: !isTextFirst }
          )}
        >
          <TextImgBackgroundFull info={info.header}></TextImgBackgroundFull>
          <div className={'pb-40 pt-40 pl-80 pr-80 text-center'}>
            <Text text={info.text}></Text>
          </div>
          {cta && (
            <div className={classNames('row', 'justify-content-center')}>
              <Button cta={info.cta}></Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default BigTextrendsCard;
