import * as React from 'react';
import '../style/components/twoColsCard.scss';

// components
import Text from '../components/text';
import Button from './_partials/_button';

const MAX_MOBILE_WIDTH = 992;

class TwoColsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { info } = this.props;

    return (
      <>
        <div className='twoColsCard d-block mx-auto'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-5 overflowHidden'>
              <img src={info.image.src} alt={info.image.alt} />
            </div>
            <div className='col-12 col-sm-12 col-md-7'>
              <div className='paddingTextRight'>
                <Text text={info.text}></Text>
                {info.cta && <Button cta={info.cta}></Button>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TwoColsCard;
